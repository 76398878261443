import React from 'react';
import VoucherUploadForm from './VoucherUploadForm';

const VoucherUpload = () => {
    return (
        <React.Fragment>
            <VoucherUploadForm />
        </React.Fragment>
    )
}

export default VoucherUpload;