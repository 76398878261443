import React from 'react';
import { Link as RouterLink,  Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LayersIcon from '@mui/icons-material/Layers';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import FactoryIcon from '@mui/icons-material/Factory';
import { useUser } from '../../UserContext';
import sameie_logo from '../../assets/images/sameie_logo.png';
import AccountMenu from './Account/AccountMenu';

const drawerWidth = 240;

const roleSysadmin = 'sysadmin';
const roleController = 'controller';
const roleVoucher = "voucher";

const MainLayout = ({header, children }) => {
    const { user } = useUser();

    let all = [
        {name: 'Hjem', nav: '/', icon: (<InboxIcon />), roles: [roleSysadmin, roleController ] }, 

        { type: 'divider', text: 'Reporting', roles: [roleSysadmin, roleController]},
        { name: 'Balanse', nav: '/reporting/balance', icon: (<AccountBalanceIcon />), roles: [roleSysadmin, roleController] },
        { name: 'Resultat', nav: '/reporting/result', icon: (<CurrencyPoundIcon />), roles: [roleSysadmin, roleController] },
        { name: 'Posteringer', nav: '/reporting/events', icon: (<ArrowCircleRightIcon />), roles: [roleSysadmin, roleController] },

        { type: 'divider', text: 'Actions', roles: [roleSysadmin] },
        {name: 'Register Bank', nav: '/registerevents', icon: (<ArrowCircleRightIcon />), roles: [roleSysadmin] },
        {name: 'Register Free', nav: '/register/registerfree', icon: (<ArrowCircleRightIcon />), roles: [roleSysadmin] },
        {name: 'Generate', nav: '/generate-scheduled', icon: (<FactoryIcon />), roles: [roleSysadmin] },
        {name: 'Attachments', nav: '/register/attachments', icon: (<ArrowCircleRightIcon />), roles: [roleSysadmin] },

        {type: 'divider', text: 'Reports', roles: [roleSysadmin] },
        { name: 'Events', nav: '/events/events', icon: (<FactoryIcon />), roles: [roleSysadmin] },

        {type: 'divider', text: 'Data', roles: [roleSysadmin] },
        { name:'BankAccounts', nav: '/bankaccounts', icon: (<AccountBalanceIcon />), roles: [roleSysadmin] },
        { name:'Documents', nav: '/documents', icon: (<AccountBalanceIcon />), roles: [roleSysadmin] }, 
        { name: 'Accounting Events', nav: '/accountingevents', icon: (<LayersIcon />), roles: [roleSysadmin] },
        { name: 'Sections', nav: '/sections', icon: (<ApartmentIcon />), roles: [roleSysadmin] },
        { name: 'Counterparts', nav: '/counterparts', icon: (<ApartmentIcon />), roles: [roleSysadmin] },
        { name: 'Rents', nav: '/sectionrents', icon: (<CurrencyPoundIcon />), roles: [roleSysadmin] },
        { name: 'Accounts', nav: '/accounts', icon: (<CurrencyPoundIcon />), roles: [roleSysadmin] },
        { type: 'divider', roles: [roleSysadmin] }, 
        { name: 'Drafts', nav: '', icon: (<InboxIcon />), roles: [roleSysadmin] },
        { type: 'divider', text: 'Users', roles: [roleSysadmin] },
        { name: 'User overview', nav: '/users/overview', icon: (<AccountBalanceIcon />), roles: [roleSysadmin] },

        { type: 'divider', text: 'Kasserer', roles: [roleSysadmin, roleVoucher] },
        { name: 'Bilagsoversikt', nav: '/vouchers/view', icon: (<LayersIcon />), roles: [roleSysadmin, roleVoucher]},
        { name: 'Last opp bilag', nav: '/vouchers/upload', icon: (<AccountBalanceIcon />), roles: [roleSysadmin, roleVoucher] },
    ];

    let toplevel = [];

    all.map(i => {
        if (user.roles.some(r => i.roles.some(a => r == a))) {
            toplevel.push(i);
        }
    });

    const renderNavItem = (item, index) => {
        if (!item.type) {
            return (
                <ListItem key={index} disablePadding>
                    <ListItemButton component={RouterLink} to={item.nav}>
                        <ListItemIcon>
                            { item.icon }
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                    </ListItemButton>
                </ListItem>
            );
        }

        switch(item.type) {
            case 'divider':
                return (
                    <Divider sx={{ my:2 }} key={index}>{item.text ?? ""}</Divider>
                );
            case 'submenu':
                return (
                    <Divider />
                );
        }

        return;
    }

    return (
        <Box sx={{display: 'flex'}}>
            <AppBar>
                <Toolbar>
                    <Box>
                        <Typography variant="h6" noWrap component="div">
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer sx={{
                width: drawerWidth,
                flexShrink:0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant="permanent"
            anchor="left"
            >
                <Toolbar>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <img src={sameie_logo} alt="Logo" style={{ width: '70%', height: 'auto', maxWidth: '200px' }} />
                    </Box>
                </Toolbar>
                <Divider />
                <List>
                    {toplevel.map((item, index) => renderNavItem(item, index) )}
                </List>

            </Drawer>
            <Box component="main" sx={{flexGrow: 1, p:3, paddingTop: 0 }}>
                <Box sx={{display: 'flex', flexDirection: 'row', zIndex: 1200}}>
                    <Typography variant="h4" gutterBottom sx={{zIndex: 1200, position: 'relative', paddingTop: '10px', paddingBottom: '10px', color: 'primary.contrastText'}}>
                        { header }
                    </Typography>
                    <Box sx={{flexGrow: 1, zIndex:1200, display: 'flex', justifyContent: 'flex-end'}}>
                        <AccountMenu />
                    </Box>
                </Box>
                { children }
                <Outlet />
            </Box>
        </Box>
    );
};

export default MainLayout;