import React, { useEffect, useState } from 'react';
import { useDocuments } from '../Shared/ApiServiceHook';
import { formatDate } from '../../services/formatUtils';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

const VouchersUploadedView = () => {
    const docApi = useDocuments();
    const [docs, setDocs] = useState([]);

    useEffect(() => {
        refresh();
    }, []);

    const refresh = async () => {
        const response = await docApi.getWithTags();
        
        if (response) {
            const f = response.filter(d => {
                return d.documentType == 2;
            });
            console.log(f);
            setDocs(f);
        }
    }

    const getTag = (doc, tagKey) => {
        let tag = '';

        doc.documentTags.forEach(t => {
            if (t.tagKey == tagKey) {
                tag = t.tag;
            }
        });

        return tag;
    }

    return (
        <React.Fragment>
            <TableContainer sx={{minHeight: '20vh'}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Filnavn</TableCell>
                                <TableCell>Dato</TableCell>
                                <TableCell>Beløp</TableCell>
                                <TableCell>Motpart</TableCell>
                                <TableCell>Beskrivelse</TableCell>
                                <TableCell>Opplastningsdato</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {docs.map(f => (
                                <TableRow key={f.documentID}>
                                    <TableCell>{f.fileName}</TableCell>
                                    <TableCell>{getTag(f, 'Date')}</TableCell>
                                    <TableCell>{getTag(f, 'Amount')}</TableCell>
                                    <TableCell>{getTag(f, 'Counterpart')}</TableCell>
                                    <TableCell>{f.description}</TableCell>
                                    <TableCell>{formatDate(f.uploadedTimestamp)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
        </React.Fragment>
    );
}

export default VouchersUploadedView