import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import GenericModal from '../Shared/GenericModal';
import UsersForm from './UsersForm';
import UserRolesForm from './UserRolesForm';
import { useApiService } from '../../services/apiService';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const UsersOverview = () => {
    const [ users, setUsers ] = useState([]);
    const [ formOpen, setFormOpen ] = useState(false);
    const [ roleFormOpen, setRoleFormOpen] = useState(false);

    const [ user, setUser ] = useState({});
    const api = useApiService();

    useEffect(() => {
        fetch();
    }, []);

    const fetch = async () => {
        const response = await api.get('/api/users');
        // const response = await axios.get(`${BASE_URL}/api/users`, {
        //     headers: {
                
        //     }
        // });

        if (response) {
            setUsers(response);
        }
    }

    const editRoles = (usr) => {
        setUser(usr);
        setRoleFormOpen(true);
    }

    const openCreateForm = () => {
        setFormOpen(true);
    }

    const closeRoleForm = () => {
        fetch();
        setRoleFormOpen(false);
    }

    const closeUserForm = () => {
        fetch();
        setFormOpen(false);
    }

    return (
        <React.Fragment>
            <h3>Users</h3>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Username</TableCell>
                        <TableCell>Roles</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {users.map(u => (
                    <TableRow key={u.id}>
                        <TableCell>{u.email}</TableCell>
                        <TableCell>{u.roles.join(",")}</TableCell>
                        <TableCell><Button onClick={() => editRoles(u)}>Edit roles</Button></TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>

            <Button onClick={openCreateForm}>Create</Button>
            
            <GenericModal open={formOpen}>
                <UsersForm onClose={closeUserForm} />
            </GenericModal>
            <GenericModal open={roleFormOpen}>
                <UserRolesForm onClose={closeRoleForm} userId={user.id} username={user.email} userRoles={user.roles} ></UserRolesForm>
            </GenericModal>
        </React.Fragment>
    )
}

export default UsersOverview;