import { useApiService } from '../../services/apiService';

export const useAccounts = () => {
    const api = useApiService();

    const get = async () => {
        return await api.get("/api/Accounts");
    }

    const getUnique = async (id) => {
        return await api.get(`/api/Accounts/${id}`);
    }

    const create = async (ev) => {
        return await api.post(`/api/Accounts/`, ev);
    }

    const update = async (ev) => {
        return await api.put(`/api/Accounts/`, ev);
    }

    return { get, getUnique, create, update };
}

export const useAccountingEvents = () => {
    const api = useApiService();

    const get = async () => {
        return await api.get("/api/accountingevents");
    }

    const getUnique = async (id) => {
        return await api.get(`/api/accountingevents/${id}`);
    }

    const create = async (ev) => {
        return await api.post(`/api/accountingevents/`, ev);
    }

    const update = async (ev) => {
        return await api.put(`/api/accountingevents/`, ev);
    }

    return { get, getUnique, create, update };
}

export const useAccountingEventCounterparts = () => {
    const api = useApiService();

    const getWP = async (accountingEventId) => {
        return await api.get(`/api/AccountingEventCounterpartActions/wp?accountingEventID=${accountingEventId}`);
    }

    const getUnique = async (id) => {
        return await api.get(`/api/AccountingEventCounterparts/${id}`);
    }

    const create = async (ev) => {
        return await api.post(`/api/AccountingEventCounterparts/`, ev);
    }

    const update = async (ev) => {
        return await api.put(`/api/AccountingEventCounterparts/`, ev);
    }

    const execDelete = async (id) => {
        return await api.execDelete(`/api/AccountingEventCounterparts/${id}`);
    }

    return { getWP, getUnique, create, update, execDelete };
}

export const useAccountingEventInstances = () => {
    const api = useApiService();
    
    const get = async () => {
        return await api.get("/api/accountingeventinstances");
    } 

    const createWithEvBank = async (payload) => {
        return await api.post('/api/accountingeventinstanceactions/CreateWithEvBank', payload);
    }

    const createFree = async (payload) => {
        return await api.post('/api/accountingeventinstanceactions/createfree', payload);
    }

    const updateInfo = async(payload) => {
        return await api.post('/api/accountingeventinstanceactions/updateinfo', payload);
    }

    const generateScheduled = async (payload) => {
        return await api.post('/api/accountingeventinstanceactions/generateScheduled', payload);
    }

    const execDelete = async (id) => {
        return await api.execDelete(`/api/accountingeventinstances/${id}`);
    }

    const execDeleteList = async (payload) => {
        return await api.post('/api/accountingeventinstanceactions/deleteList', payload);
    }

    return { get, createWithEvBank, createFree, updateInfo, generateScheduled, execDelete, execDeleteList };
}

export const useAccountingEventInstanceReports = () => {
    const api = useApiService();

    const list = async (payload) => {
        return await api.post("/api/accountingeventinstancereports/list", payload);
    }

    return { list };
}

export const useAccountingEventSchedules = () => {
    const api = useApiService();

    const getWP = async (accountingEventId) => {
        return await api.get(`/api/AccountingEventScheduleActions/wp?accountingEventId=${accountingEventId}`);
    }

    const getUnique = async (id) => {
        return await api.get(`/api/AccountingEventSchedules/${id}`);
    }

    const create = async (ev) => {
        return await api.post(`/api/AccountingEventSchedules/`, ev);
    }

    const update = async (ev) => {
        return await api.put(`/api/AccountingEventSchedules/`, ev);
    }

    const execDelete = async (id) => {
        return await api.execDelete(`/api/AccountingEventSchedules/${id}`);
    }

    return { getWP, getUnique, create, update, execDelete };
}

export const useBankAccounts = () => {
    const api = useApiService();
    
    const get = async () => {
        return await api.get("/api/bankaccounts");
    };

    const getUnique = async(id) => {
        return await api.get(`/api/bankaccounts/${id}`);
    }

    const create = async (bankAccount) => {
        return await api.post("/api/bankaccounts", bankAccount);
    };

    const update = async (bankAccount) => {
        return await api.put("/api/bankaccounts/", bankAccount);
    }

    return { get, getUnique, create, update };
};

export const useBankTransactionImport = () => {
    const api = useApiService();
    
    const bankTransactionImportParse = async (id, text) => {
        return await api.post("/api/banktransactionimport/parse", { bankAccountID: id, text: text});
    }

    const bankTransactionImport = async (id, transactions) => {
        return await api.post("/api/banktransactionimport/import", { bankAccountID: id, bankTransactions: transactions });
    }

    return { bankTransactionImportParse, bankTransactionImport };
}

export const useBankTransactions = () => {
    const api = useApiService();
    
    const get = async() => {
        return await api.get("/api/banktransactions");
    }

    const getByAccount = async (bankAccountId) => {
        return await api.get("/api/banktransactionactions/transactions/" + bankAccountId);
    }

    const getUnlinked = async() => {
        return await api.get("/api/banktransactionactions/unlinked");
    }

    const execDelete = async(id) => {
        return await api.execDelete(`/api/banktransactions/${id}`);
    }

    return { get, getByAccount, getUnlinked, execDelete };
}

export const useCounterparts = () => {
    const api = useApiService();

    const get = async () => {
        return await api.get("/api/counterparts");
    }

    const getUnique = async (id) => {
        return await api.get(`/api/counterparts/${id}`);
    }

    const create = async (ev) => {
        return await api.post(`/api/counterparts/`, ev);
    }

    const update = async (ev) => {
        return await api.put(`/api/counterparts/`, ev);
    }

    const execDelete = async (id) => {
        return await api.execDelete(`/api/counterparts/${id}`);
    }

    return { get, getUnique, create, update, execDelete };
}

export const useDocuments = () => {
    const api = useApiService();
    
    const getWithTags = async () => {
        return await api.get("/api/documentactions/getwithtags");
    }

    const uploadFiles = async (type, files, tags) => {
        const formData = new FormData();
        
        files.forEach((file, index) => {
            formData.append(`Files[${index}].FormFile`, file.formFile);
            formData.append(`Files[${index}].Description`, file.description);
            formData.append(`Files[${index}].LastModified`, file.lastModified);

            if (file.fileTags) {
                file.fileTags.forEach((tag, tagIndex) => {
                    formData.append(`Files[${index}].FileTags[${tagIndex}].TagKey`, file.fileTags[tagIndex].tagKey);
                    formData.append(`Files[${index}].FileTags[${tagIndex}].Tag`, file.fileTags[tagIndex].tag);
                });
            }
        });

        formData.append('DocumentType', type.toString());
        
        tags.forEach((tag, index) => {
            formData.append(`Tags[${index}]`, tags[index]);
        });

        const response = await api.postRaw('/api/documentactions/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response && response.status == 200;
    }

    const getDocumentWithUrl = async (id) => {
        return await api.get(`/api/documentactions/getDocumentWithUrl/${id}`);
    }

    const attachVouchers = async (documentList, instanceList) => {
        return await api.post('/api/documentactions/attachVouchers', {
            documentIDList: documentList,
            instanceList: instanceList
        });
    }

    return { getWithTags, uploadFiles, getDocumentWithUrl, attachVouchers };
}

export const useSections = () => {
    const api = useApiService();

    const get = async () => {
        return await api.get("/api/sections");
    }

    const getUnique = async (id) => {
        return await api.get(`/api/sections/${id}`);
    }

    const create = async (ev) => {
        return await api.post(`/api/sections/`, ev);
    }

    const update = async (ev) => {
        return await api.put(`/api/sections/`, ev);
    }

    const execDelete = async (id) => {
        return await api.execDelete(`/api/sections/${id}`);
    }

    const createMultiple = async(params) => {
        return await api.post('/api/sectionactions/multiple', params);
    }

    const fetchInfo = async() => {
        return await api.get('/api/sectionactions/fetchinfo');
    }

    return { get, getUnique, create, update, execDelete, createMultiple, fetchInfo };
}

export const useSectionRents = () => {
    const api = useApiService();

    const get = async () => {
        return await api.get("/api/sectionrents");
    }

    const getWP = async (sectionId) => {
        return await api.get(`/api/sectionrentactions/wp?sectionId=${sectionId}`);
    }

    const getAppliedRents = async (rentDate) => {
        let encodedDate = "";
        if (rentDate) {
            const formattedDate = rentDate.format('YYYY-MM-DD');
            encodedDate = encodeURIComponent(formattedDate);
        }

        return await api.get(`/api/sectionrentactions/appliedRents?rentDate=${encodedDate}`);
    }

    const getUnique = async (id) => {
        return await api.get(`/api/sectionrents/${id}`);
    }

    const create = async (ev) => {
        return await api.post(`/api/sectionrents/`, ev);
    }

    const update = async (ev) => {
        return await api.put(`/api/sectionrents/`, ev);
    }

    const registerMultiple = async (payload) => {
        return await api.post('/api/sectionrentactions/registermultiple', payload);
    }

    const execDelete = async (id) => {
        return await api.execDelete(`/api/sectionrents/${id}`);
    }

    return { get, getWP, getAppliedRents, getUnique, create, update, registerMultiple, execDelete };
}