import React, { useState, useEffect } from 'react';
import { TextField, Button, FormControl } from '@mui/material';
import { useApiService } from '../../services/apiService';

const UsersForm = ({ onClose }) => {
    const [entity, setEntity] = useState({ customerID: 1, email: '', password: '' });
    const api = useApiService();

    const handleCreateOrUpdate = async (ev) => {
        ev.preventDefault();
        const response = await api.post('/authorization/create-user', entity);

        console.log(response);
        onClose();
    };

    return (
        <React.Fragment>
            <form onSubmit={(event) => handleCreateOrUpdate(event)} className="samport-form">
                <FormControl>
                    <TextField
                        label="Email"
                        fullWidth
                        value={entity.email}
                        onChange={(e) => setEntity({ ...entity, email: e.target.value })}
                    />
                </FormControl>

                <FormControl>
                    <TextField
                        label="Password"
                        fullWidth
                        value={entity.password}
                        onChange={(e) => setEntity({ ...entity, password: e.target.value })}
                    />
                </FormControl>

                <Button type="submit">Create</Button>
                </form>
        </React.Fragment>
    );
};

export default UsersForm;
