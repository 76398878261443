import React, { useState, useEffect } from 'react';

import { useDocuments } from '../Shared/ApiServiceHook';
import { Stack, Button, Table, TableHead, TableBody, TableContainer, TableRow, TableCell, FormControl, Card, CardContent, Typography, IconButton, TextField, Paper, Grid, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import { uuidv4 } from '../../services/samportUtils';
import { formatDecimal, formatSize } from '../../services/formatUtils';
import * as dayjs from 'dayjs';
import { useDefaultizedTimeField } from '@mui/x-date-pickers/internals';
import { DateField } from '@mui/x-date-pickers/DateField';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const VoucherUploadForm = () => {
    const [files, setFiles] = useState([]);
    const [tag, setTag] = useState('');
    const [tags, setTags] = useState(['voucher']);
    const [isUploading, setIsUploading] = useState(false);

    const [uploadCompletedMode, setUploadCompletedMode] = useState(false);

    const docApi = useDocuments();

    const handleFileChange = (ev) => {
        const nfs = [];

        for (let i = 0; i < ev.target.files.length; i++) {
            console.log('reading file ' + i);
            let f = ev.target.files[i];

            if (!f.length) {
                f.length = f.size;
            }

            let msg = '';
            if (files.find(e => e.formFile.name == f.name)) {
                msg = 'Duplicate filename!';
            }

            nfs.push({
                recordID: uuidv4(),
                formFile: f,
                description: '',
                lastModified: f.lastModified,
                fileTags: [],
                message: msg,
                amount: '',
                counterpart: '',
                date: ''
            });
        }

        setFiles(currentFiles => [...currentFiles, ...nfs]);
    };

    const handleUpload = async () => {
        setIsUploading(true);

        const updFiles = files.map(f => {
            f.fileTags = [];
            
            f.fileTags.push({ tagKey: 'Amount', tag: f.amount });
            f.fileTags.push({ tagKey: 'Date', tag: f.date });
            f.fileTags.push({ tagKey: 'Counterpart', tag: f.counterpart });

            return f;
        });

        console.log(updFiles);

        const result = await docApi.uploadFiles(2, updFiles, tags);
        
        if (result) {
            setTags(['voucher']);
            setFiles([]);

            setUploadCompletedMode(true);
        }

        setIsUploading(false);
    }

    const deleteFile = (f) => {
        const n_files = files.filter(e => e.recordID !== f.recordID);

        setFiles(n_files);
    }

    const getSize = (size) => {
        return formatSize(size);
    }

    const handleDescriptionChange = (recordID, newDescription) => {
        const updatedFiles = files.map(file => {
            if (file.recordID === recordID) {
                return { ...file, description: newDescription };
            }
            return file;
        });

        setFiles(updatedFiles);
    };

    const handleCounterpartChanged = (recordID, newCounterpart) => {
        const updatedFiles = files.map(file => {
            if (file.recordID === recordID) {
                return { ...file, counterpart: newCounterpart};
            }
            return file;
        });

        setFiles(updatedFiles);
    }

    const handleAmountChanged = (recordID, newAmount) => {
        const updatedFiles = files.map(file => {
            if (file.recordID === recordID) {
                return { ...file, amount: newAmount};
            }
            return file;
        });

        setFiles(updatedFiles);
    }

    const handleDateChanged = (recordID, newDate) => {
        const updatedFiles = files.map(file => {
            if (file.recordID === recordID) {
                return { ...file, date: newDate};
            }
            return file;
        });

        setFiles(updatedFiles);
    }

    if (uploadCompletedMode) {
        return (
            <React.Fragment>
                <Stack spacing={2}>
                    <Card sx={{p:2}}>
                        <Typography variant='h4' sx={{textAlign: 'center', marginBottom: '12px'}}>
                            Opplastning fullført
                        </Typography>
                        <Grid item xs={12}>
                            <Button fullWidth variant='contained' onClick={() => setUploadCompletedMode(false)}>Last opp flere</Button>
                        </Grid>
                    </Card>
                </Stack>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Stack spacing={2} sx={{minHeight: '40vh'}}>
                {isUploading && (<progress />)}
                
                <Grid item xs={12} >
                    <Button
                        fullWidth
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                    >
                        Velg filer
                        <VisuallyHiddenInput type="file" multiple onChange={handleFileChange} />
                    </Button>
                </Grid>
                
                <TableContainer sx={{minHeight: '20vh'}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Filnavn</TableCell>
                                {/* <TableCell>Type</TableCell> */}
                                <TableCell>Størrelse</TableCell>
                                <TableCell>Dato</TableCell>
                                <TableCell>Motpart</TableCell>
                                <TableCell>Beløp</TableCell>
                                <TableCell>Beskrivelse</TableCell>
                                {/* <TableCell>Melding</TableCell> */}
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {files.map(f => (
                                <TableRow key={f.recordID}>
                                    <TableCell>{f.formFile.name}</TableCell>
                                    {/* <TableCell>{f.formFile.type}</TableCell> */}
                                    <TableCell>{getSize(f.formFile.size)}</TableCell>
                                    <TableCell>
                                        <TextField type="text" onChange={(ev) => handleDateChanged(f.recordID, ev.target.value)} value={f.date} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField type="text" onChange={(ev) => handleCounterpartChanged(f.recordID, ev.target.value)} value={f.counterpart} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField type="number" onChange={(ev) => handleAmountChanged(f.recordID, ev.target.value)} value={f.amount} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField type="text" onChange={(ev) => handleDescriptionChange(f.recordID, ev.target.value)} value={f.description} />
                                    </TableCell>
                                    {/* <TableCell>{f.message}</TableCell> */}
                                    <TableCell>
                                        <IconButton onClick={() => deleteFile(f)}><DeleteIcon /></IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid item xs={12}>
                    <Button fullWidth variant="contained" color="secondary" onClick={handleUpload}>Last opp alle</Button>
                </Grid>
            </Stack>
        </React.Fragment>
    );
}

export default VoucherUploadForm;